import React from 'react'
import styled from 'styled-components';

export default function SearchButton({ clickHandler }) {
    return (
        <Container>
            <Button onClick={() => clickHandler()}>
                <span>Search for anything</span>
                <i className="fa fa-search"></i>
            </Button>
        </Container>
    )
}
const Container = styled.div`
    padding: 8px 0;
`;
const Button = styled.button`
    background: transparent;
    border: none;
    outline: none;

    span {
        font-size: 20px;
        color: rgb(84, 84, 84);
        margin-right: 10px;
    }
`;
